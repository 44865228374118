




















































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { validationMixin } from "vuelidate";
import { Component } from "vue-property-decorator";
import { required, email } from "vuelidate/lib/validators";
import api from "@/core/utils/api";

type LoginType = {
  email: string;
  password: string;
};

@Component({
  mixins: [validationMixin],
  validations: {
    emailInput: { required, email },
    passwordInput: { required },
  },
})
export default class Login extends Vue {
  @Getter("profile/loading") loading!: boolean;
  @Action("clearEverything") clearEverything!: (reroute: boolean) => void;
  @Action("profile/loginUser") loginUser!: (data: LoginType) => Promise<void>;
  @Getter("profile/isAuthenticated") isAuthenticated!: boolean;

  showPass = false;
  emailInput = "";
  passwordInput = "";

  get emailErrors(): any[] {
    const errors: any[] = [];
    if (!this.$v.emailInput!.$dirty) return errors;
    !this.$v.emailInput!.email &&
      errors.push(this.$t("loginSection.invalidEmail"));
    !this.$v.emailInput!.required &&
      errors.push(this.$t("loginSection.emailRequired"));
    return errors;
  }

  get passwordErrors(): any[] {
    const errors: any[] = [];
    if (!this.$v.passwordInput!.$dirty) return errors;
    !this.$v.passwordInput!.required &&
      errors.push(this.$t("loginSection.passRequired"));
    return errors;
  }

  async submit(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid && this.passwordErrors.length === 0) {
      this.clearEverything(false);
      this.loginUser({
        email: this.emailInput,
        password: this.passwordInput,
      }).then(async () => {
        await this.authenticateDevice();
      });
    }
  }

  async authenticateDevice() {
    const { deviceCode } = this.$route.query;
    if (!deviceCode) return;

    try {
      const end = "/api/Account/ActivateDeviceCode";
      const data = { deviceCode };
      await api.post(end, data).then(async res => {
        console.log(res.data);
        await this.$router.push(
          `/auth/device/activate?deviceCode=${deviceCode}&success=true`,
        );
      });
    } catch (error) {
      const msg = this.$t("registerDeviceSection.couldNotGetCode").toString();
      console.error(msg);
    }
  }

  beforeDestroy() {
    this.emailInput = "";
    this.passwordInput = "";
  }

  mounted() {
    if (this.isAuthenticated) this.authenticateDevice();
  }
}
